import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function usePrivilegeList() {
    const userListTable = ref([])
    const tableColumns = [
        { text: '权限名称', value: 'name' },
        { text: '权限类型', value: 'source_type' },
        // {text: '目标类型', value: 'target_type', sortable: false},
        { text: '目标', value: 'target', sortable: false },
        { text: '创建时间', value: 'create_at', sortable: false },
        { text: '描述', value: 'desc', sortable: false },
    ]
    const totalUserListTable = ref(0)
    const loading = ref(false)
    const options = ref({ sortBy: ['id'], sortDesc: [true], })
    const table = ref({
        open: false,
        msg: '',
    })
    const enums = {
        types: { "RESOURCE": "资源权限", "INTERFACE": "接口权限" },
        typesColor: { "RESOURCE": "info", "INTERFACE": "primary" },
    }
    const fetchPrivilegeList = () => {
        loading.value = true
        store.dispatch('app-system/fetchPrivilegeList', { q: {}, options: options.value, }).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value = {
                    open: true,
                    msg: `错误码：${code}, ${msg}`
                }
                loading.value = false
                return
            }
            const { list, total } = res.data.data
            userListTable.value = list || []
            totalUserListTable.value = total
            loading.value = false
        }).catch(error => {
            loading.value = false
            console.log(error)
        })
    }
    watch([options], () => {
        fetchPrivilegeList()
    }, { deep: true })
    return {
        tableColumns,
        userListTable,
        totalUserListTable,
        loading,
        options,
        table, enums
    }
}
