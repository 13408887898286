<template>
    <div id='user-list'>
        <v-snackbar v-model="table.open" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ table.msg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="table.open = false"> 关闭 </v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-data-table :headers='tableColumns' :items='userListTable' :loading='loading' :options.sync='options'
                :server-items-length='totalUserListTable'>
                <template #[`item.source_type`]='{ item }'>
                    <v-chip :color="enums.typesColor[item.source_type]" small>{{ enums.types[item.source_type] }}</v-chip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import { mdiDeleteOutline, mdiDotsVertical, mdiFileDocumentOutline } from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import systemStoreModule from '../systemStoreModule'
import usePrivilegeList from './usePrivilegeList'

export default {
    components: {}, setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-system'
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, systemStoreModule)
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const { userListTable, tableColumns, totalUserListTable, loading, options, table, enums } = usePrivilegeList()
        return {
            userListTable, tableColumns, totalUserListTable, loading, options, table, enums,
            icons: { mdiFileDocumentOutline, mdiDotsVertical, mdiDeleteOutline },
        }
    },
} </script>
<style lang='scss'> @import '@core/preset/preset/apps/user.scss';
</style>
